import React from "react";
import { graphql, PageProps } from "gatsby";

import { Link } from "gatsby";
import { IntlProvider, FormattedMessage } from "react-intl";
import Layout from "../components/Layout";
import LocalesContext from "../context/LocalesContext";
import messages from "../../i18n-translations.json";
import { defaultLanguage } from "../i18n";
import WarningIcon from "../images/svg/icons/warning-icon.svg";
import PostsList from "../components/posts/PostsList";

const Page404 = (props: PageProps) => {
  const data = Object.values(props.data);

  const avaiableLangs = [
    "en",
    "da",
    "de",
    "es",
    "fr",
    "it",
    "nl",
    "nb",
    "pl",
    "pt",
    "fi",
    "sv",
    "tr",
    "el",
    "ru",
    "ja",
    "ko",
  ];

  const pathName = props.location.pathname;
  const langCode = pathName.split(`/`)[1];
  let locale: string;
  if (avaiableLangs.includes(langCode)) {
    locale = langCode;
  } else {
    locale = defaultLanguage ?? "";
  }

  const filterLangPosts = data.filter((post) => {
    if (post.nodes.length === 0 || post.nodes[0].language.slug !== locale) {
      return null;
    }
    return post;
  });

  const posts = filterLangPosts[0]?.nodes;

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale as keyof typeof messages] as Record<any, any>}
    >
      <LocalesContext.Provider
        value={{
          slug: "",
          avaiableLangs: avaiableLangs,
          pageNumber: 0,
        }}
      >
        <Layout>
          <div className="page-404">
            <div className="page-404__container">
              <div className="page-404__content">
                <div className="page-404__icon">
                  <WarningIcon />
                </div>

                <div className="page-404__title">
                  <h1>
                    <FormattedMessage id="404_title" defaultMessage="404" />
                  </h1>
                </div>
                <div className="page-404__subtitle">
                  <h2>
                    <FormattedMessage id="404_subtitle" />
                  </h2>
                </div>
                <div className="page-404__copy">
                  <FormattedMessage id="404_copy" />
                </div>
                <div className="page-404__link">
                  <Link
                    className="button button--large"
                    to={
                      process.env.GATSBY_MULTILINGUAL === "true"
                        ? `/${locale}`
                        : `/`
                    }
                  >
                    <FormattedMessage id="404_home" />
                  </Link>
                </div>
              </div>
              <div className="page-404__posts">
                {posts && (
                  <PostsList
                    posts={posts}
                    category="Our Latest Posts"
                    limit={4}
                    modifier="page-404"
                  />
                )}
              </div>
            </div>
          </div>
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default Page404;

export const query = graphql`
  query {
    en: allWpPost(
      filter: {
        categories: {
          nodes: {
            elemMatch: {
              slug: { nin: ["uncategorized", "uncategorized-en", "non-classe"] }
            }
          }
        }
        language: { slug: { eq: "en" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    da: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-da" } } }
        }
        language: { slug: { eq: "da" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    de: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-de" } } }
        }
        language: { slug: { eq: "de" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    es: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { nin: "non-classe-es" } } } }
        language: { slug: { eq: "es" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    fr: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-fr" } } }
        }
        language: { slug: { eq: "fr" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    it: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-it" } } }
        }
        language: { slug: { eq: "it" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    nl: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-nl" } } }
        }
        language: { slug: { eq: "nl" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    nb: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-nb" } } }
        }
        language: { slug: { eq: "nb" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    pl: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-pl" } } }
        }
        language: { slug: { eq: "pl" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    pt: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-pt" } } }
        }
        language: { slug: { eq: "pt" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    fi: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-fi" } } }
        }
        language: { slug: { eq: "fi" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    sv: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-sv" } } }
        }
        language: { slug: { eq: "sv" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    tr: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-tr" } } }
        }
        language: { slug: { eq: "tr" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    el: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-el" } } }
        }
        language: { slug: { eq: "el" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    ru: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-ru" } } }
        }
        language: { slug: { eq: "ru" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    ja: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-ja" } } }
        }
        language: { slug: { eq: "ja" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }

    ko: allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { nin: "uncategorized-ko" } } }
        }
        language: { slug: { eq: "ko" } }
      }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        excerpt
        originalSlug
        language {
          slug
        }
        date
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            title
            mediaItemUrl
            mediaDetails {
              filteredSizes {
                name
                sourceUrl
              }
              sizes {
                name
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`;
